import { generateOtp, verifyOtp } from "@api/authentications";
import Button from "@components/Button";
import Input from "@components/Input";
import OtpInput from "@components/OtpInput";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useTranslations } from "@context/translations";
import {
  getOtpRequestEvent,
  getOtpResendEvent,
  getOtpVerifyEvent,
  getOtpVerifyStatusEvent,
} from "@helpers/genzo/genzoEventActionsBuilder";
import useCountDownTimer from "@helpers/useCountDownTimer";
import validateEmailPattern from "@helpers/validateEmailPattern";
import ArrowLeft from "@icons/arrowLeft.svg";
import { AuthenticationStep } from "@shopcashTypes/authenticationStep";
import { VariantButton } from "@shopcashTypes/variantButton";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import styles from "./OtpSignupStep.module.scss";

interface OtpSignupStepProps {
  email: string;
  onEmailSave: (email: string) => void;
  onBackClick: () => void;
  onNextStep: (nextStep: AuthenticationStep) => void;
  onOtpRequestIdSet: (otpRequestId: string) => void;
  otpRequestId: string;
}

enum OtpSignupState {
  Form = "form",
  Otp = "otp",
}

const OtpSignupStep: React.FC<OtpSignupStepProps> = ({
  email,
  onEmailSave,
  onBackClick,
  onNextStep,
  onOtpRequestIdSet,
  otpRequestId,
}) => {
  const { isRtl } = useSiteConfig();
  const [updatedEmail, setUpdatedEmail] = useState<string>(email);
  const [state, setState] = useState<OtpSignupState>(OtpSignupState.Form);

  const handleOnBackClick = () => {
    if (state === OtpSignupState.Otp) {
      setState(OtpSignupState.Form);
    } else {
      onBackClick();
    }
  };

  const handleOnEmailChange = (email: string): void => {
    onEmailSave(email);
    setUpdatedEmail(email);
  };

  return (
    <div className={clsx(styles.container, isRtl && styles.rtl)}>
      <div className={styles.backContainer} onClick={handleOnBackClick}>
        <ArrowLeft className={styles.back} />
      </div>

      {state === OtpSignupState.Form && (
        <StateForm
          updatedEmail={updatedEmail}
          onEmailChange={handleOnEmailChange}
          onOtpSignupClick={(otpRequestId) => {
            onOtpRequestIdSet(otpRequestId);
            setState(OtpSignupState.Otp);
          }}
        />
      )}
      {state === OtpSignupState.Otp && (
        <StateOtp
          email={updatedEmail}
          otpRequestId={otpRequestId}
          onOtpResendClick={(otpRequestId) => {
            onOtpRequestIdSet(otpRequestId);
          }}
          onNextStep={onNextStep}
        />
      )}
    </div>
  );
};

interface StateFormProps {
  updatedEmail: string;
  onEmailChange: (email: string) => void;
  onOtpSignupClick: (otpRequestId: string) => void;
}

const StateForm: React.FC<StateFormProps> = ({
  updatedEmail,
  onEmailChange,
  onOtpSignupClick,
}) => {
  const translate = useTranslations();
  const { locale } = useSiteConfig();
  const [emailMessage, setEmailMessage] = useState<string>();
  const [hasEmailError, setHasEmailError] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { trackEvent } = useGenzoTracking();

  const handleOtpRequestEvent = () => {
    const event = getOtpRequestEvent();
    trackEvent(event);
  };

  useEffect(() => {
    setIsDisabled(!updatedEmail);
  }, [updatedEmail]);

  const handleOnOtpSignupClick = (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    handleOtpRequestEvent();
    setIsLoading(true);
    const { errorMessageKey, hasError } = validateEmailPattern(updatedEmail);
    setEmailMessage(translate(errorMessageKey));
    setHasEmailError(hasError);
    if (hasError) {
      setIsLoading(false);
      return;
    }

    // Call generate OTP
    generateOtp(updatedEmail, "sign_up", locale)
      .then((r) => {
        setIsLoading(false);
        if (r.ok) {
          // When email is valid, proceed to otp signup confirmation state.
          r.json().then((r: OtpResponse) => {
            onOtpSignupClick(r.otpRequest?.id);
            return;
          });
        } else {
          return r.json().then((r: ErrorResponse) => {
            setEmailMessage(r.error.message);
            setHasEmailError(true);
          });
        }
      })
      .catch((error) => {
        // log to sentry
        // Handle Errors here.
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className={styles.title}>{translate("create_acc_title")}</div>
      <div className={styles.note}>{translate("joinnow_disclaimer")} 🎉</div>
      <form onSubmit={handleOnOtpSignupClick}>
        <Input
          id="email"
          name="email"
          type="text"
          label={translate("email")}
          message={emailMessage}
          defaultValue={updatedEmail}
          hasError={hasEmailError}
          placeholder="example@email.com"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onEmailChange(e.target.value)
          }
        />

        <div className={styles.buttonContainer}>
          <Button
            variant={VariantButton.Primary}
            large
            fullWidth
            disabled={isDisabled}
            loading={isLoading}
          >
            {translate("next")}
          </Button>
        </div>
      </form>
    </>
  );
};
interface StateOtpProps {
  email: string;
  otpRequestId: string;
  onOtpResendClick: (otpRequestId: string) => void;
  onNextStep: (nextStep: AuthenticationStep) => void;
}

const StateOtp: React.FC<StateOtpProps> = ({
  email,
  otpRequestId,
  onOtpResendClick,
  onNextStep,
}) => {
  const { locale } = useSiteConfig();
  const translate = useTranslations();
  const { trackEvent } = useGenzoTracking();
  const [otpValue, setOtpValue] = useState<string>();
  const [otpMessage, setOtpMessage] = useState<string>("");
  const [hasOtpError, setHasOtpError] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [displayTimer, timer, setTimer] = useCountDownTimer(45);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const otpInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    otpInputRef.current.focus();
  }, []);

  const handleOnOtpInputChange = (value: string): void => {
    setOtpValue(value);
    setIsDisabled(value.length !== 6);
  };

  const handleOtpVerifyEvent = () => {
    const event = getOtpVerifyEvent();
    trackEvent(event);
  };

  const handleOtpVerifyStatusEvent = (success: boolean) => {
    const event = getOtpVerifyStatusEvent(success);
    trackEvent(event);
  };

  const handleOnVerifyEmailClick = (
    event: React.FormEvent<HTMLFormElement>
  ): void => {
    event.preventDefault();
    handleOtpVerifyEvent();
    setIsLoading(true);
    // Call Verify OTP
    verifyOtp(otpValue, otpRequestId)
      .then((r) => {
        setIsLoading(false);
        if (r.ok) {
          handleOtpVerifyStatusEvent(true);
          // When OTP is valid, proceed to signup step.
          onNextStep(AuthenticationStep.SignupStep);
          return;
        } else {
          return r.json().then((r) => {
            handleOtpVerifyStatusEvent(false);
            setOtpMessage(translate("invalid_otp"));
            setHasOtpError(false);
          });
        }
      })
      .catch((error) => {
        // log to sentry
        // Handle Errors here.
        handleOtpVerifyStatusEvent(false);
        setIsLoading(false);
      });
  };

  const handleOtpResendEvent = () => {
    const event = getOtpResendEvent();
    trackEvent(event);
  };

  const handleOnResendOtpClick = async () => {
    // Call generate OTP for resend OTP
    handleOtpResendEvent();

    generateOtp(email, "sign_up", locale)
      .then((r: Response) => {
        if (r.ok) {
          r.json().then((r: OtpResponse) => {
            const otpRequestId = r.otpRequest?.id;
            onOtpResendClick(otpRequestId);
            return;
          });
        }
      })
      .catch((error) => {
        // log to sentry
        // Handle Errors here.
      });

    setTimer(45);
  };

  return (
    <>
      <div className={styles.title}>{translate("verify_email")}</div>
      <div className={styles.note}>
        {translate("verify_email_body", [""])}
        <div className={styles.emailText}>{email}</div>
      </div>
      <form onSubmit={handleOnVerifyEmailClick}>
        <div className={styles.OtpInput}>
          <OtpInput
            ref={otpInputRef}
            id="otp"
            name="otp"
            message={otpMessage}
            hasError={hasOtpError}
            onChange={handleOnOtpInputChange}
          />
        </div>

        <div className={styles.buttonContainer}>
          <Button
            variant={VariantButton.Primary}
            large
            fullWidth
            disabled={isDisabled}
            loading={isLoading}
          >
            {translate("verify_email")}
          </Button>
        </div>
      </form>
      {timer !== 0 ? (
        <div className={styles.otpTimer}>
          {translate("otp_sent", [displayTimer])}
        </div>
      ) : (
        <div className={styles.resendOtp} onClick={handleOnResendOtpClick}>
          {translate("otp_ask")} <span>{translate("resend_otp")}</span>
        </div>
      )}
    </>
  );
};

export default OtpSignupStep;
